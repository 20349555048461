import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {
        derived_properties: {frame_type: {
          in: ["hopfällbar"]
        }}
      }) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hopfällbara"
    }}>{`Hopfällbara`}</h1>
    <p>{`En hopfällbar cykel, även kallad vikbar cykel, är precis som namnet antyder en cykel som man kan fälla ihop. En hoppfällbar cykel har i regel väldigt små hjul och relativt liten ram. Sadeln och styret har väldigt höga "stolpar" för att man ska kunna sitta någorlunda bekvämt. Låga däck och liten ram gör att cykeln kan vara lite mer trögcyklad jämfört med andra cyklar.`}</p>
    <h2 {...{
      "id": "när-ska-jag-köpa-en-hopfällbar-cykel"
    }}>{`När ska jag köpa en hopfällbar cykel?`}</h2>
    <p>{`Syftet är egentligen ganska självklart. En hoppfällbar cykel är för dig som behöver en cykel som du enkelt ska kunna ta med dig. Detta kan vara om du t.ex. pendlar kollektivt till jobbet, och behöver en cykel för att transportera dig mellan hemmet och busshållplatsen eller tågstationen och jobbet. Du kan då cykla mellan de olika transportsätten och sedan enkelt fälla ihop cyklen och ta med dig på bussen eller tåget.`}</p>
    <p>{`En hopfällbar cykel är även optimalt att ha med sig t.ex. på mindre båtar eller i husvagnen. Detta då det kan vara sjönt att ha ett lite snabbare transportsätt när du behöver ta dig mellan till exempel hamnen och mataffären.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      